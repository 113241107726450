import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";

export default function ScheduleBarChart({ dashboardList }) {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const monthlyBookedCourts = dashboardList?.monthlyBookedCourts || [];
    const months = Array.from({ length: 12 }, (_, i) => i + 1);
    const bookedCourtsData = months.map((month) => {
      const court = monthlyBookedCourts.find((court) => court.month === month);
      return court ? court.count : 0;
    });

    const colors = [
      "#FFB3B3",
      "#FFCC99",
      "#FFFF99",
      "#CCFF99",
      "#99FF99",
      "#99CCFF",
      "#CC99FF",
      "#FF99CC",
      "#FFCCFF",
      "#CCCCFF",
      "#D9EAD3",
      "#EAD1DC",
    ];



    const data = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "Booked Courts",
          data: bookedCourtsData,
          backgroundColor: colors,
          borderColor: colors,
          borderWidth: 1,
          barThickness: 40,
          maxBarThickness: 40,
        },
      ],
    };

    const options = {
      scales: {
        y: {
          beginAtZero: true,
          max: 100,
          ticks: {
            stepSize: 10,
          },
          stacked: false,
        },
        x: {
          grid: {
            display: false,
          },
          stacked: true,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          callbacks: {
            label: function (context) {
              return context.dataset.label + ": " + context.raw;
            },
          },
        },
      },
      responsive: true,
      aspectRatio: 2,
    };

    setChartData(data);
    setChartOptions(options);
  }, [dashboardList]);

  return (
    <div>
      <Chart type="bar" data={chartData} options={chartOptions} />
    </div>
  );
}
