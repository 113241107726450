import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../../../config";
import { toast } from "react-toastify";

// Initial state
const initialState = {
  tellerList: [],
  complexListTeller:[],
  loading: false,
  error: null,
  totalRecords: 0,
  currentPage: 1,
  pageSize: 10,
  updateSuccess: null,
};

// Slice definition
const tellerReducer = createSlice({
  name: "tellerManagement",
  initialState,
  reducers: {
    resetChangeStatus(state) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
      state.deleteSuccess = undefined;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setPageSize(state, action) {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTeller.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createTeller.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.addSuccess = action.payload;
        // console.log (action.payload)
        toast.success(action.payload);
      })
      .addCase(createTeller.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        // toast.error(action.payload);
      })
      .addCase(getTellersList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTellersList.fulfilled, (state, action) => {
        state.loading = false;
        state.tellerList = action.payload.data;
        state.totalRecords = action.payload.recordsTotal;
      })
      .addCase(getTellersList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(getCitiesWithSportsComplexesList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCitiesWithSportsComplexesList.fulfilled, (state, action) => {
        state.loading = false;
        state.complexListTeller = action.payload;
      })
      .addCase(getCitiesWithSportsComplexesList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteSuccess = action.payload;
        state.tellerList = state.tellerList.filter(
          (admin) => admin.id !== action.payload
        );
        toast.success("Teller Inactive successfully");
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(updateTeller.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTeller.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess = action.payload;
        // console.log (action.payload)
        toast.success(action.payload);
      })
      .addCase(updateTeller.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(getTellersById.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTellersById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.tellerList = action.payload.data;
      })
      .addCase(getTellersById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export reducer and actions
export default tellerReducer.reducer;
export const { resetChangeStatus } = tellerReducer.actions;


export const getTellersList = createAsyncThunk(
  "tellerManagement/getTellersList",
  async ({ token, pageNo, pageSize, searchText, isActive }, { rejectWithValue }) => {
    try {
      const response = await Axios.post(
        `${config.baseUrl}${config.checkerAppPath}/GetTellersList`,
        { pageNo, pageSize, searchText, isActive },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "tellerManagement/deleteUser",
  async ({ adminId, token }, { rejectWithValue }) => {
    try {
      const response = await Axios.delete(
        `${config.baseUrl}${config.checkerAppPath}/DeleteUser/${adminId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return adminId;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  }
);



export const getTellersById = createAsyncThunk(
  "tellerManagement/getTellersById",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      const response = await Axios.get(
        `${config.baseUrl}${config.checkerAppPath}/GetTellersById/${id}`, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);


export const createTeller = createAsyncThunk(
  "tellerManagement/createTeller",
  async (adminData, { rejectWithValue }) => {
    try {
      const { token, ...data } = adminData;

      const response = await Axios.post(
        `${config.baseUrl}${config.checkerAppPath}/createTeller`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

export const updateTeller = createAsyncThunk(
  "tellerManagement/updateTeller",
  async (adminData, { rejectWithValue }) => {
    try {
      const { token, ...data } = adminData;

      const response = await Axios.put(
        `${config.baseUrl}${config.checkerAppPath}/updateTeller`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

export const getCitiesWithSportsComplexesList = createAsyncThunk(
  "tellerManagement/CitiesWithSportsComplexesList",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await Axios.get(
        `${config.baseUrl}${config.checkerAppPath}/CitiesWithSportsComplexesList`, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);
