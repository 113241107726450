import React from "react";

const NotFoundPage = ({ }) => (
  <div
    style={{
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      marginLeft:"0 !important",
      color:"black"
    }}
  >
    <h1>404 - Page Not Found</h1>
    <p>Sorry, the page you are looking for does not exist.</p>
  </div>
);

export default NotFoundPage;
 