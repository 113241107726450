import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
import { useFormik, FormikProvider, Form } from "formik";
import { InputTextarea } from "primereact/inputtextarea";

import CustomImagePreview from "../../../components/custom_imagepreview";

const ViewDetailsRegister = () => {
  const location = useLocation();
  const history = useHistory();

  const [rowData, setRowData] = useState(() => {
    const storedData = sessionStorage.getItem("viewDetailsData");
    return storedData
      ? JSON.parse(storedData)
      : location.state?.rowData || null;
  });

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("viewDetailsData");
    };
  }, []);

  const handleCancelClick = () => {
    history.push("/register-checker");
  };

  return (
    <>
      <div className="main-form">
        <div className="form-heading">
          <h5>View Details</h5>
        </div>
        <div className="p-fluid formgrid grid">
          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
            <label htmlFor="name">Name</label>
            <InputText value={rowData?.name || ""} readOnly />
          </div>
          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
            <label htmlFor="name">Mobile Number</label>
            <InputText value={rowData?.mobileNumber || ""} readOnly />
          </div>
          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
            <label htmlFor="name">Pin</label>
            <InputText value={rowData?.pin || ""} readOnly />
          </div>

          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
            <label htmlFor="email">Email</label>
            <InputText
              placeholder="Enter email"
              value={rowData?.email || "N/A"}
              readOnly
            />
          </div>
          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
            <label htmlFor="name">Status</label>
            <InputText value={rowData?.status || ""} readOnly />
          </div>
          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
            <label htmlFor="name">City</label>
            <InputText value={rowData?.cityName || "N/A"} readOnly />
          </div>
          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
            <label htmlFor="name">Complex Name</label>
            <InputText value={rowData?.complexName || ""} readOnly />
          </div>
          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
            <label htmlFor="name">Remarks</label>
            <InputTextarea
              value={rowData?.remarks || "N/A"}
              rows={5} cols={30}
              readOnly
            />
          </div>

          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
            <label htmlFor="ProfileImageFile">Image</label>

            {rowData?.image && (
              <div className="preview-image">
                <CustomImagePreview src={rowData.image} alt="Profile" />
              </div>
            )}
            
          </div>
        </div>
        <div className="flex buttons">
          <Button
            label="Cancel"
            className="p-button-secondary"
            onClick={handleCancelClick}
          />
        </div>
      </div>
    </>
  );
};

export default ViewDetailsRegister;
