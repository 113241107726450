import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, roles, user, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      user ? (
        roles.includes(user?.user?.role) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

export default PrivateRoute;
