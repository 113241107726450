import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";



export default function CourtsBarChart({ tellerDashboardStats }) {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  
  useEffect(() => {
    const monthlyBookedCourts =
    tellerDashboardStats?.monthlyBookedCourts || [];
    const months = Array.from({ length: 12 }, (_, i) => i + 1);
    const bookedCourts = months.map((month) => {
      const court = monthlyBookedCourts.find(
        (court) => court.month === month
      );
      return court ? court.count : 0;
    });
    const colors = [
      "#FFE0B2",
      "#FFABAB",
      "#FFC3A0",
      "#B9FBC0",
      "#C2C2F0",
      "#E2C6D0",
      "#F7B7A3",
      "#D3F8E2",
      "#F0E6F6",
      "#B2EBF2",
      "#C8E6CA",
      "#FCE4EC",
    ];

    const data = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "Booked Courts",
          data: bookedCourts,
          backgroundColor: colors,
          borderColor: colors,
          borderWidth: 1,
          barThickness: 40,
          maxBarThickness: 40,
        },
      ],
    };

    const options = {
      scales: {
        y: {
          beginAtZero: true,
          max: 100,
          ticks: {
            stepSize: 10, 
          },
          stacked: false,
        },
        x: {
          grid: {
            display: false,
          },
          stacked: true,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          callbacks: {
            label: function (context) {
              return context.dataset.label + ": " + context.raw;
            },
          },
        },
      },
      responsive: true,
      aspectRatio: 2,
    };

    setChartData(data);
    setChartOptions(options);
  }, [tellerDashboardStats]);

  return (
    <div>
      <Chart type="bar" data={chartData} options={chartOptions} />
    </div>
  );
}